import { useState, useLayoutEffect } from 'react';
import { checkScreen } from 'util/screen';
const useDimScreen = () => {
  const [dimension, setdimension] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
    screen: checkScreen(window.innerWidth),
  });
  useLayoutEffect(() => {
    const checkScreenDim = () => {
      const w = window.innerWidth;
      const h = window.innerHeight;
      const type = checkScreen(w);
      const newDim = { width: w, height: h, screen: type };
      setdimension(newDim);
    };
    window.addEventListener('resize', checkScreenDim);
    return () => {
      window.removeEventListener('resize', checkScreenDim);
    };
  }, []);
  return dimension;
};
export default useDimScreen;
