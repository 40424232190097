import { MEDIA_SCREEN } from 'config/general';

export const checkScreen = (w) => {
  let type = null;
  if (w >= MEDIA_SCREEN.xlg) {
    type = 'xlg';
  } else if (w >= MEDIA_SCREEN.lg) {
    type = 'lg';
  } else if (w >= MEDIA_SCREEN.med) {
    type = 'med';
  } else if (w >= MEDIA_SCREEN.sm) {
    type = 'sm';
  } else {
    type = 'xsm';
  }
  return type;
};
