import React from 'react';
import { TIMELINE_SERVICES } from 'config/general';
import Undraw from 'react-undraw';
const TimeLine = () => {
  return (
    <>
      <div className="about-resources-wrapper">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-lg-6 order-2 order-lg-1">
              <div className="resources-left-box">
                <div className="resources-inner">
                  <h3 className="heading">
                    Infrastructure de production et de paiement en ligne
                  </h3>
                  <p className="text mt-30">
                    Nous priorisons les plateformes cloud de{' '}
                    <a
                      className="text-color-primary"
                      href="https://firebase.google.com/"
                    >
                      Google Firebase
                    </a>{' '}
                    (Base de données NoSql, hébergement, stockage ...) afin
                    d'assurer la fiabilité et la sécurité des applications.
                    Nous travaillons avec{' '}
                    <a
                      className="text-color-primary"
                      href="https://stripe.com/fr"
                    >
                      Stripe
                    </a>{' '}
                    et{' '}
                    <a
                      className="text-color-primary"
                      href="https://www.paypal.com/fr/"
                    >
                      Paypal
                    </a>{' '}
                    pour les solutions de paiement en ligne des sites ecommerce.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 order-1 order-lg-2">
              <div className="resources-right-box">
                <div className="resources-right-inner text-center">
                  <div className="resources-images">
                    <img
                      className="img-fluid"
                      src="assets/images/banners/banner_card_firebase.png"
                      alt=""
                    />
                    {/*  <Undraw
                      name="undraw_going_up_ttm5"
                      className="img-fluid"
                      primaryColor="#5734ee"
                    />*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="timeline-area section-space--ptb_70">
        <div className="container">
          <h6 className="section-sub-title mb-20 text-center">
            Nos étapes de réalisation d'un projet client ainsi que le délai
            général de chaque phase.
          </h6>
          <div className="row">
            <div className="col-lg-12">
              <div className="ht-timeline style-01">
                <ul className="tm-timeline-list ht-animation-queue">
                  <li className="line"></li>
                  {TIMELINE_SERVICES.map((item, idx) => {
                    return (
                      <li className="item animate" key={item.step}>
                        <div className="dots">
                          <div className="middle-dot"></div>
                        </div>

                        <div className="row">
                          <div className="col-md-6 timeline-col left timeline-feature">
                            <div className="inner">
                              <div className="date-wrap">
                                <h2 className="year">{item.step}</h2>
                              </div>
                              <div
                                className={`photo ${
                                  item.marginTop ? 'mt-tl' : '{}'
                                }`}
                              >
                                <Undraw
                                  name={item.img}
                                  className="img-fluid"
                                  primaryColor="#5734ee"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 timeline-col right timeline-info">
                            <div className="inner">
                              <div className="content-wrap">
                                <div className="content-body">
                                  <h6 className="heading">{item.label}</h6>
                                  <div className="text">{item.text}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default TimeLine;
