import React from 'react';
import { NavLink } from 'react-router-dom';
import { ROUTE_HOME } from 'config/general.js';
const Logo = ({ pathImg, cssImg, isMobile, onClickMenuItem }) => {
  const handleClickMenuItem = (e) => {
    if (isMobile) onClickMenuItem();
  };
  return (
    <>
      {/* brand logo */}
      <NavLink
        activeClassName="active"
        to={ROUTE_HOME.path}
        onClick={(e) => handleClickMenuItem(e)}
      >
        <img src={pathImg} className={cssImg} alt="" />
        {!isMobile && (
            <p className="light">Creativity Web Media Consulting</p>
        )}
      </NavLink>
    </>
  );
};
export default Logo;
