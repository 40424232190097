import { auth, storageRef, storage } from 'firebase/firebaseIndex';
import { db } from 'firebase/firebaseIndex';
export const dbModel = {
  save: async (collectionName, dataDoc) => {
    return genericModel.save(collectionName, dataDoc);
  },
};

export const genericModel = {
  save: async (collectionName, dataDoc) => {
    return new Promise(async (resolve, reject) => {
      dataDoc.createdAt = new Date();
      try {
        let doc = await db
          .collection(collectionName)
          .add(dataDoc)
          .catch((error) => {
            console.error(error);
            reject(error);
          });
        doc = await db.collection(collectionName).doc(doc.id).get(); //refresh
        resolve(doc);
      } catch (error) {
        reject(error);
      }
    });
  },
};
