import React, { useRef } from 'react';
import useScroll from 'hook/useScroll';
import { FaArrowUp } from 'react-icons/fa';
const ScrollUp = () => {
  const upBtnRef = useScroll('show', 200);
  const scrollToTop = (e) => {
    e.preventDefault();
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  return (
    <>
      <a
        ref={upBtnRef}
        href={void(0)}
        className="scroll-top"
        onClick={(e) => scrollToTop(e)}
      >
        {/*  <FaArrowUp className="arrow-top" /> */}
        <FaArrowUp className="arrow-top" />
        <FaArrowUp className="arrow-bottom" />
        {/*         <i className="arrow-top fal fa-long-arrow-up"></i>
        <i className="arrow-bottom fal fa-long-arrow-up"></i> */}
      </a>
    </>
  );
};
export default ScrollUp;
