import React from 'react';
import { NavLink } from 'react-router-dom';
import { ROUTE_CONTACT } from 'config/general.js';
import Transition from 'component/plugin/Transition';

import { FaRocketchat } from 'react-icons/fa';
const Footer = () => {
  return (
    <Transition>
      <footer className="footer-area-preview">
        <div className="footer-preview section-space--ptb_60 prv-section-footer-bg">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 ml-auto mr-auto">
                <div className="footer-preview-content section-space--ptb_70 text-center">
                  <h2 className="heading text-white">
                    Conseil et dévelopement de logiciel
                  </h2>
                  <h6 className="heading  text-white mt-25">
                    &copy; 2021 Creativity Web Media Consulting.&nbsp;
                    <a href="https://www.creativitywebm.com/">
                      Tous droits réservés.
                    </a>
                  </h6>

                  <div className="header-button downlode-btn mt-40">
                    <NavLink to={ROUTE_CONTACT.path} className="btn btn--white">
                      <FaRocketchat className="mx-2" />
                      DITES-NOUS EN PLUS ?
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </Transition>
  );
};
export default Footer;
