import React, { useState, useLayoutEffect } from 'react';
import useDimScreen from 'hook/useDimScreen';
export const AppContext = React.createContext();
const AppProvider = (props) => {
  const [loading, setloading] = useState(true);
  const dimScreen = useDimScreen();
  useLayoutEffect(() => {
    const timeout = setTimeout(() => {
      setloading(false);
    }, 200);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <AppContext.Provider value={{ loading, setloading,dimScreen }}>
      {props.children}
    </AppContext.Provider>
  );
};

export default AppProvider;
