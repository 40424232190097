import React from 'react';
import Transition from 'component/plugin/Transition';
import SwiperBrand from 'component/plugin/SwiperBrand';
const AboutUs = () => {
	return (
		<Transition>
			<React.Fragment>
				<div className="contact-us-section-wrappaer infotechno-contact-us-bg section-space--ptb_120">
					<div className="container">
						<div className="row">
							<div className="col-lg-8 ml-auto mr-auto">
								<div className="about-banner-content">
									<h1 className="mb-15 text-center">Creativity Web Media Consulting</h1>
									<h5 className="font-weight--normal text-justify">
										Nous accompagnons nos clients depuis la conception jusqu'à la réalisation des
										logiciels informatiques.
										<br />
										Nous soutenons des jeunes start-up, des TPE et/ou TME dans les démarches rapides
										pour adopter des applications web et mobile de meilleures qualités avec un prix
										optimal du marché.
										<br />
										Nous enrichissons des contenus multimédias de votre site web comme les vidéos,
										les clips de présentation, la publicité, etc.
									</h5>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div>
					<h4 className="text-center mt-5">Quelques technologies que nous apprécions particulièrement</h4>
				</div>
				<div class="brand-logo-slider-area section-space--ptb_60">
					<div class="container">
						<div class="row">
							<div class="col-lg-12">
								<div class="brand-logo-slider__container-area">
									<SwiperBrand cssSwiper="swiper-container brand-logo-slider__container swiper-container-initialized swiper-container-horizontal" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		</Transition>
	);
};
export default AboutUs;
