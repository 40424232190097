import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CardMinimal from 'component/payment/stripe/CardMinimal';
import CardDetailed from 'component/payment/stripe/CardDetailed';
import CardButton from 'component/payment/paypal/CardButton';
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUB_KEY}`);

const CheckoutForm = () => {
  const onSuccessCheckout = (res) => {
    console.log('success', res);
  };
  return (
    <Elements stripe={stripePromise}>
      <CardButton onSuccessCheckout={onSuccessCheckout} />
    </Elements>
  );
};
export default CheckoutForm;
