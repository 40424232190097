import React, { useLayoutEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/swiper-bundle.min.css';

import { BRAND_LOGO, SWIPER_PARAMS_BRAND } from 'config/general';
import { FaArrowRight } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import Undraw from 'react-undraw';

SwiperCore.use([Pagination, A11y]);

const SwiperBrand = ({ cssSwiper, ...props }) => {
	return (
		<Swiper
			pagination={{ clickable: true }}
			breakpoints={SWIPER_PARAMS_BRAND.breakpoints}
			spaceBetween={30}
			className={cssSwiper}
		>
			<div className="swiper-wrapper brand-logo-slider__one">
				{BRAND_LOGO.map((item, i) => {
					const [key, value] = Object.entries(item)[0];
					return (
						<SwiperSlide key={key} className="brand-logo brand-logo--slider swiper-slide-active">
							<a href={void 0} className="projects-wrap style-01 wow move-up animated">
								<div className="brand-logo__image">
									<img src={value} className="img-fluid" alt="" style={{ maxWidth: '110px' }} />
								</div>
							</a>
						</SwiperSlide>
					);
				})}
			</div>
		</Swiper>
	);
};
export default SwiperBrand;
