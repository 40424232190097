import React from 'react';
import { NavLink } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import {
  ROUTE_ABOUT_US,
  ROUTE_SERVICES,
  ROUTE_CONTACT,
} from 'config/general.js';
const MenuList = ({ onClickMenuItem, isMobile }) => {
  const location = useLocation();
  const handleClickMenuItem = (e) => {
    if (isMobile) onClickMenuItem();
  };
  return (
    <ul>
      <li
        className={`${
          location.pathname.startsWith(ROUTE_ABOUT_US.path) ? 'active' : ''
        }`}
      >
        <NavLink
          to={ROUTE_ABOUT_US.path}
          onClick={(e) => handleClickMenuItem(e)}
        >
          <span>{ROUTE_ABOUT_US.label}</span>
        </NavLink>
      </li>
      <li
        className={`${
          location.pathname.startsWith(ROUTE_SERVICES.path) ? 'active' : ''
        }`}
      >
        <NavLink
          to={ROUTE_SERVICES.path}
          onClick={(e) => handleClickMenuItem(e)}
        >
          <span>{ROUTE_SERVICES.label}</span>
        </NavLink>
      </li>
      <li
        className={`${
          location.pathname.startsWith(ROUTE_CONTACT.path) ? 'active' : ''
        }`}
      >
        <NavLink
          to={ROUTE_CONTACT.path}
          onClick={(e) => handleClickMenuItem(e)}
        >
          <span>{ROUTE_CONTACT.label}</span>
        </NavLink>
      </li>
    </ul>
  );
};
export default MenuList;
