import React, { useContext } from 'react';
import { AppContext } from 'provider/AppProvider';
import { CSSTransition } from 'react-transition-group';
import 'component/plugin/transition.css';
const Spinner = (props) => {
  const { loading } = useContext(AppContext);
  return (
    <>
      <CSSTransition
        in={loading}
        timeout={200}
        classNames="spinner"
        unmountOnExit
      >
        <div className="preloader-activate preloader-active open_tm_preloader">
          <div className="preloader-area-wrap">
            <div className="spinner d-flex justify-content-center align-items-center h-100">
              <div className="bounce1"></div>
              <div className="bounce2"></div>
              <div className="bounce3"></div>
            </div>
          </div>
        </div>
      </CSSTransition>
    </>
  );
};

export default Spinner;
