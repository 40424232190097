import React from 'react';
import Transition from 'component/plugin/Transition';

const PageNotFound = () => {
  return (
    <Transition>
      <div className="typed-text-wrapper section-space--ptb_120">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 ml-auto mr-auto">
              <div className="typed-text-wrap">
                <h4 className="cd-headline letters type">
                  <span>La page n'existe pas</span>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  );
};
export default PageNotFound;
