import React, { useState, useLayoutEffect } from 'react';
import Undraw from 'react-undraw';
import { getRandomInt } from 'util/math';
import { CSSTransition } from 'react-transition-group';
import 'component/plugin/transition.css';
const ImgUndraw = ({ images, height, cssClass, timeslide }) => {
  const [currentimg, setcurrentimg] = useState(
    images[getRandomInt(0, images.length - 1)]
  );
  const [show, setshow] = useState(true);
  useLayoutEffect(() => {
    let timeout;
    const interval = setInterval(
      () => {
        if (images.length > 1) {
          setshow(false);
          timeout = setTimeout(() => {
            const rd = getRandomInt(0, images.length - 1);
            setcurrentimg(images[rd]);
            setshow(true);
          }, 200);
        }
      },
      timeslide ? timeslide : 5000
    );
    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };
  }, []);
  return (
    <>
      <CSSTransition in={show} timeout={200} classNames="generic-item" appear>
        <Undraw height={height} name={currentimg} className={cssClass} primaryColor="#398ce1"/>{/* #d2a98e 7cacff */}
      </CSSTransition>
    </>
  );
};
export default ImgUndraw;
