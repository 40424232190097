import React, { useLayoutEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/swiper-bundle.min.css';

import {
  HOME_SLIDERS,
  HOME_SERVICES,
  ROUTE_CONTACT,
  SWIPER_PARAMS_IMGMED,
} from 'config/general';
import { FaArrowRight } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import Undraw from 'react-undraw';

SwiperCore.use([Pagination, A11y]);

const SwiperServices = ({ cssSwiper, ...props }) => {
  return (
    <Swiper
      pagination={{ clickable: true }}
      breakpoints={SWIPER_PARAMS_IMGMED.breakpoints}
      spaceBetween={10}
      className={cssSwiper}
    >
      <div className="swiper-wrapper service-slider__wrapper feature-images__three">
        {HOME_SERVICES.map((item) => {
          return (
            <SwiperSlide key={item.heading}>
              {/* ht-box-icon Start */}
              <a
                href={void(0)}
                className="projects-wrap style-01 wow move-up animated"
              >
                <div className="projects-image-box">
                  <div className="projects-image text-center">
                    {/* <img className="img-fulid" src={item.img} alt="" /> */}
                    <Undraw
                      height="80px"
                      name={item.img}
                      className="img-fulid"
                    />
                  </div>
                  <div className="content">
                    <h6 className="heading  text-center">{item.heading} </h6>
                    <div className="text text-justify">{item.text}</div>
                    <NavLink to={ROUTE_CONTACT.path}>
                      <div className="box-projects-arrow">
                        <span className="button-text">{item.footing}</span>
                        <FaArrowRight className="fa-long-arrow-right ml-1" />
                      </div>
                    </NavLink>
                  </div>
                </div>
              </a>
              {/* ht-box-icon End */}
            </SwiperSlide>
          );
        })}
      </div>
    </Swiper>
  );
};
export default SwiperServices;
