import React from 'react';
const TransportServices = () => {
  return (
    <>
      <div className="layouts-section-wrap layout-section-bg section-space--ptb_70">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="layouts-images text-left mt-30">
                <img
                  className="img-fluid"
                  src="assets/images/preview/mitech-landing-header-preview-image.png"
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="layouts-section-text">
                <h3 className="heading">
                  <span className="text-color-primary">
                    Solutions de logistique
                  </span>{' '}
                  <br /> et d'expédition
                </h3>
                <p className="text mt-30">
                  Vous souhaitez concentrer sur votre business et passer moins
                  de temps sur la logistique, externaliser les services de
                  livraison aux plateformes intermédiaires. Nous vous proposons{' '}
                  <a href="https://www.boxtal.com/">Boxtal</a> et <a href="https://www.sendcloud.fr/">SendCloud</a> qui travaillent avec plusieurs
                  transporteurs (Colissimo, Chonopost, Colis Privé, UPS, Fedex,
                  etc.) En seulement quelques clics, vous pouvez créer les
                  étiquettes d'expédition, suivre l'acheminement et gérer les
                  retours avec les prix intéressants et négociables.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default TransportServices;
