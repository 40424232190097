import React, { useState, useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ROUTE_HOME } from 'config/general.js';
import { ROUTES } from 'config/general.js';
import { Link } from 'react-router-dom';
import Transition from 'component/plugin/Transition';

const Breadcrumb = () => {
  const [breadItem, setbreadItem] = useState(null);
  const location = useLocation();

  useLayoutEffect(() => {
    let pathList = location.pathname ? location.pathname.split('/') : [];
    //format location.pathname  : '/home', '/aboutus', ....
    if (pathList.length > 0) {
      const newBread = {};
      let itemLocated = ROUTES[pathList[pathList.length - 1]];
      if (!itemLocated) {
        itemLocated = ROUTES.notfound;
      }
      newBread.item = itemLocated;
      newBread.list = [];
      newBread.list.push(ROUTES.home);
      for (let i = 1; i < pathList.length - 1; i++) {
        newBread.list.push(ROUTES[pathList[i]]);
      }
      setbreadItem(newBread);
    }
  }, [location]);
  if (location.pathname === ROUTE_HOME.path || location.pathname === '/')
    return null;
  return (
    <>
      {breadItem && (
        <Transition>
          <div
            className="breadcrumb-area"
            style={{
              backgroundImage: `${process.env.PUBLIC_URL}/assets/images/bg/title-bar-02-bg.svg`,
            }}
          >
            {/* breadcrumb-area start */}
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="breadcrumb_box text-center">
                    <h2 className="breadcrumb-title">
                      {breadItem.item.label}
                    </h2>
                    {/* breadcrumb-list start */}
                    <ul className="breadcrumb-list">
                      {breadItem.list.map((e, i) => {
                        return (
                          <li key={e.label} className="breadcrumb-item">
                            <Link to={e.path}>{e.label}</Link>
                          </li>
                        );
                      })}
                      {/* <li className="breadcrumb-item">
                      <a href="index.html">Home</a>
                    </li>*/}
                      <li className="breadcrumb-item active">
                        {breadItem.item.label}
                      </li>
                    </ul>
                    {/* breadcrumb-list end  */}
                  </div>
                </div>
              </div>
            </div>
            {/* breadcrumb-area end */}
          </div>
        </Transition>
      )}
    </>
  );
};
export default Breadcrumb;
