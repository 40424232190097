import React, { useRef, useLayoutEffect } from 'react';
import useClickOutside from 'hook/useClickOutside';
import {
  ROUTE_ABOUT_US,
  ROUTE_SERVICES,
  ROUTE_CONTACT,
} from 'config/general.js';
import Logo from 'component/menu/Logo';
import MenuList from 'component/menu/MenuList';
const MobileMenu = ({ activated, closeMobileMenu }) => {
  const mobileMenuRef = useRef();
  const innerRef = useRef();

  const onClickMenuItem = () => {
    closeMobileMenu();
  };
  useLayoutEffect(() => {
    const handleClickOutside = (event) => {
      if (innerRef.current) {
        if (!innerRef.current.contains(event.target)) {
          closeMobileMenu();
        }
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div
      className={`mobile-menu-overlay ${activated ? 'active' : ''}`}
      ref={mobileMenuRef}
      id="mobile-menu-overlay"
    >
      <div className="mobile-menu-overlay__inner" ref={innerRef}>
        <div className="mobile-menu-overlay__header">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-md-6 col-8">
                {/* logo */}
                <div className="logo">
                  <Logo
                    pathImg={`${process.env.PUBLIC_URL}/assets/images/logo/logo.png`}
                    cssImg="img-fluid"
                    isMobile={true}
                    onClickMenuItem={onClickMenuItem}
                  />
                </div>
              </div>
              <div className="col-md-6 col-4">
                {/* mobile menu content */}
                <div className="mobile-menu-content text-right">
                  <span
                    className="mobile-navigation-close-icon"
                    id="mobile-menu-close-trigger"
                    onClick={closeMobileMenu}
                  ></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mobile-menu-overlay__body">
          <nav className="offcanvas-navigation offcanvas-navigation--onepage">
            <MenuList onClickMenuItem={onClickMenuItem} isMobile={true} />
          </nav>
        </div>
      </div>
    </div>
  );
};
export default MobileMenu;
















