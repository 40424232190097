import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { CSSTransition } from 'react-transition-group';
import 'component/plugin/transition.css';
const AlertAutoDismiss = ({
  timecount,
  heading,
  text,
  handleCallback,
  variant,
  ...props
}) => {
  const [show, setShow] = useState(true);
  useEffect(() => {
    console.log('AlertAutoDismiss', timecount);
    const time = timecount ? timecount : 2000;
    const timeout = setTimeout(() => {
      setShow(false);
      handleCallback();
    }, time);
    return () => {
      clearTimeout(timeout);
    };
  }, []);
  return (
    <CSSTransition in={show} timeout={200} classNames="spinner" unmountOnExit>
      <Alert variant={variant} show={true} dismissible>
        <Alert.Heading>{heading}</Alert.Heading>
        <p>{text}</p>
      </Alert>
    </CSSTransition>
  );
};
export default AlertAutoDismiss;
