import React, { useState, useCallback, useContext } from 'react';
import _ from 'lodash/fp';
import { useForm } from 'react-hook-form';
import { genericModel } from 'firebase/db';
import { FORM_CONTACT } from 'config/model';
import { URL_FUNC_SENDMAIL } from 'config/general';
import axios from 'axios';
import AlertAutoDismiss from 'component/form/AlertAutoDismiss';
import { AppContext } from 'provider/AppProvider';
const FormContact = () => {
  const { register, handleSubmit, watch, errors } = useForm();
  const [showalert, setshowalert] = useState(false);
  const [msgalert, setmsgalert] = useState({});

  const { setloading } = useContext(AppContext);
  const onSubmit = async (data, e) => {
    try {
      setloading(true);
      const res = await genericModel.save(FORM_CONTACT.collection, data);
      const resSendMail = await axios.post(
        URL_FUNC_SENDMAIL,
        JSON.stringify(res.data()),
        {
          headers: {
            authorization: 813531603050,
            'Content-Type': 'text/xml',
            Accept: 'text/xml',
          },
        }
      );
      console.log('doc saved and send mail', resSendMail.data);
      setloading(false);
      setshowalert(true);
      e.target.reset(); // reset form
      setmsgalert({
        time: 5000,
        heading: 'Message envoyé',
        text: 'Votre message a été envoyé avec succès',
        variant: 'success',
      });
    } catch (error) {
      setshowalert(true);
      setloading(false);
      console.error(error);
      setmsgalert({
        time: 5000,
        heading: 'Message non délivré',
        text: `Une erreur est survenue lors de l'envoie de votre message`,
        variant: 'danger',
      });
    }
  };
  const handleCallbackAlert = useCallback(() => {
    setshowalert(false);
    setmsgalert({});
  }, [showalert]);
  return (
    <>
      <div className="col-lg-6 col-lg-6">
        <div className="contact-form-wrap">
          <form
            onSubmit={handleSubmit(onSubmit)}
            id="contact-form"
            className="mb-2"
          >
            <div className="contact-form">
              <div className="contact-input">
                <div className="contact-inner">
                  <input
                    name="name"
                    type="text"
                    ref={register({
                      required: true,
                      maxLength: 100,
                      pattern: /^[a-z\d\-_\s]+$/i,
                    })}
                    placeholder="Votre nom *"
                  />
                  {_.get('name.type', errors) === 'required' && (
                    <small className="text-danger">
                      Veuillez renseigner votre nom s'il vous plaît
                    </small>
                  )}
                  {_.get('name.type', errors) === 'maxLength' && (
                    <small className="text-danger">
                      Votre nom ne peut pas dépasser 100 caractères
                    </small>
                  )}
                  {_.get('name.type', errors) === 'pattern' && (
                    <small className="text-danger">
                      Veuillez renseigner votre nom avec les caractères
                      alphabétiques s'il vous plaît.
                    </small>
                  )}
                </div>
                <div className="contact-inner">
                  <input
                    name="email"
                    type="email"
                    placeholder="Votre email *"
                    ref={register({
                      required: 'Required',
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      },
                    })}
                  />
                  {_.get('email.type', errors) === 'required' && (
                    <small className="text-danger">
                      Veuillez renseigner votre email s'il vous plaît
                    </small>
                  )}
                  {_.get('email.type', errors) === 'pattern' && (
                    <small className="text-danger">
                      Le format de votre email n'est pas valide
                    </small>
                  )}
                </div>
              </div>
              <div className="contact-inner">
                <input
                  name="subject"
                  type="text"
                  placeholder="Objet du message *"
                  ref={register({
                    required: true,
                    maxLength: 100,
                    pattern: /^[a-z\d\-_\s]+$/i,
                  })}
                />
                {_.get('subject.type', errors) === 'required' && (
                  <small className="text-danger">
                    Veuillez renseigner l'objet de votre message s'il vous plaît
                  </small>
                )}
                {_.get('subject.type', errors) === 'maxLength' && (
                  <small className="text-danger">
                    L'objet du message peut pas dépasser 100 caractères
                  </small>
                )}
                {_.get('subject.type', errors) === 'pattern' && (
                  <small className="text-danger">
                    Veuillez renseigner l'objet du message avec les caractères
                    alphabétiques s'il vous plaît.
                  </small>
                )}
              </div>
              <div className="contact-inner contact-message">
                <textarea
                  name="message"
                  placeholder="Merci de décrire votre besoin le plus précisément possible"
                  ref={register({
                    required: true,
                    maxLength: 1000,
                  })}
                ></textarea>
                {_.get('message.type', errors) === 'required' && (
                  <small className="text-danger">
                    Veuillez renseigner le contenu de votre message s'il vous
                    plaît
                  </small>
                )}
                {_.get('message.type', errors) === 'maxLength' && (
                  <small className="text-danger">
                    Le du message peut pas dépasser 1000 caractères
                  </small>
                )}
              </div>
              <div className="submit-btn mt-20">
                <button className="ht-btn ht-btn-md" type="submit">
                  Envoyer
                </button>
                <p className="form-messege"></p>
              </div>
            </div>
          </form>
          {showalert && (
            <AlertAutoDismiss
              timecount={msgalert.time}
              variant={msgalert.variant}
              heading={msgalert.heading}
              text={msgalert.text}
              handleCallback={handleCallbackAlert}
            />
          )}
        </div>
      </div>
    </>
  );
};
export default FormContact;
