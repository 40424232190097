import React from 'react';
import FormContact from 'component/form/FormContact';
import Transition from 'component/plugin/Transition';

const Contact = () => {
  return (
    <Transition>
      {/* ====================  Conact us Section Start ==================== */}
      <div className="contact-us-section-wrappaer section-space--pt_100 section-space--pb_70">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-lg-6">
              <div className="conact-us-wrap-one mb-30">
                <h3 className="heading">
                  Un projet ? <br />
                  Une question ? <br />
                  Une rencontre ? <br />
                  <span className="text-color-primary">N’hésitez pas</span> à
                  nous écrire
                </h3>
                <div className="sub-heading">
                  Nous vous rappelons dans les plus brefs délais !
                </div>
              </div>
            </div>
            <FormContact />
          </div>
        </div>
      </div>
      {/* ====================  Conact us Section End  ==================== */}
    </Transition>
  );
};
export default Contact;
