import React, { useRef } from 'react';
import Logo from 'component/menu/Logo';
import Navigation from 'component/menu/Navigation';
import ActionMenu from 'component/menu/ActionMenu';
import useSticky from 'hook/useSticky';
const Header = (props) => {
  const headerRef = useSticky('is-sticky');
  return (
    <div className="header-area header-area--absolute">
      <div className="preview-header-inner header-sticky" ref={headerRef}>
        <div className="container-fluid container-fluid--cp-150">
          <div className="row">
            <div className="col-lg-12">
              <div className="header position-relative">
                <div className="header__logo">
                  <Logo
                    pathImg={`${process.env.PUBLIC_URL}/assets/images/logo/logo.png`}
                    cssImg="img-fluid"
                  />
                </div>
                <Navigation />
                <ActionMenu />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Header;
