import React, { useLayoutEffect, useRef, useContext } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/swiper-bundle.min.css';
import Transition from 'component/plugin/Transition';
import { SWIPER_PARAMS_IMGMED } from 'config/general';
import ImgUndraw from 'component/plugin/ImgUndraw';
import { HOME_SLIDERS, HOME_SERVICES } from 'config/general';
import { NavLink } from 'react-router-dom';
import { ROUTE_SERVICES,MEDIA_SCREEN } from 'config/general.js';
import Undraw from 'react-undraw';

import { AppContext } from 'provider/AppProvider';

SwiperCore.use([Pagination, A11y]);
const Home = (props) => {
  const { dimScreen } = useContext(AppContext);
  return (
    <Transition>
      <>
        <div className="preview-hero-area preview-hero-bg position-relative section-space--ptb_120">
          <div className="container-fluid container-fluid--cp-150">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="preview-hero-text wow move-up">
                  <h6 className="mb-30">
                    Conseil et Développement de logiciel
                  </h6>
                  <h2 className="font-weight--reguler text-white mb-30">
                    Votre site internet responsive et application mobile sur
                    mesure
                  </h2>
                  <div className="hero-button mt-40">
                    <NavLink
                      to={ROUTE_SERVICES.path}
                      className="btn btn--white"
                    >
                      Découvrir
                    </NavLink>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="preview-inner-img">
                  <ImgUndraw
                    images={HOME_SLIDERS}
                    height="320px"
                    timeslide="6000"
                    cssClass="img-fluid min-img animation_images one wow fadeInDown"
                  />
                </div>
              </div>
            </div>
            {
              dimScreen && dimScreen.width >MEDIA_SCREEN.lg && <>
            <ImgUndraw
              images={HOME_SLIDERS}
              height="120px"
              timeslide="19000"
              cssClass="img-fluid pr-img-02 animation_images two wow fadeInDown"
            />

            <ImgUndraw
              images={HOME_SLIDERS}
              height="150px"
              timeslide="15000"
              cssClass="img-fluid pr-img-04 animation_images two wow fadeInDown"
            />
            </>
            }

            {/* 
            <img class="img-fluid pr-img-01 animation_images two wow fadeInDown" src="assets/images/hero/mitech-landing-main-slider-slide-01-image-01.png" alt="">
                    <img class="img-fluid pr-img-02 animation_images two wow fadeInDown" src="assets/images/hero/mitech-landing-main-slider-slide-01-image-02.png" alt="">

                    <img class="img-fluid pr-img-03 animation_images two wow fadeInDown" src="assets/images/hero/mitech-landing-main-slider-slide-01-image-03.png" alt="">

                    <img class="img-fluid pr-img-04 animation_images two wow fadeInDown" src="assets/images/hero/mitech-landing-main-slider-slide-01-image-05.png" alt="">

 */}
          </div>
        </div>

        {/* ===========  feature-images-wrapper  Start ============= */}
        <div className="feature-images-wrapper position-relative">
          <Swiper
            pagination={{ clickable: true }}
            breakpoints={SWIPER_PARAMS_IMGMED.breakpoints}
            spaceBetween={10}
            className="swiper-container service-slider__container"
          >
            <div className="swiper-wrapper service-slider__wrapper feature-images__three">
              {HOME_SERVICES.map((item) => {
                return (
                  <SwiperSlide key={item.heading}>
                    {/* ht-box-icon Start */}
                    <a href={void(0)} className="ht-box-images style-03 wow move-up">
                      <div className="image-box-wrap">
                        <div className="box-image  text-center">
                          {/* <img className="img-fulid" src={item.img} alt="" /> */}
                          <Undraw
                            height="80px"
                            name={item.img}
                            className="img-fulid"
                          />
                        </div>
                        <div className="content">
                          <h5 className="heading  text-center">
                            {item.heading}{' '}
                          </h5>
                          <div className="text text-justify">{item.text}</div>
                        </div>
                      </div>
                    </a>
                    {/* ht-box-icon End */}
                  </SwiperSlide>
                );
              })}
            </div>
          </Swiper>
        </div>
        {/* feature-images-wrapper  End */}

        <div className="layouts-section-wrap section-space--pt_100 section-space--pb_60">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="layouts-images text-right">
                  <img
                    className="img-fluid"
                    src="assets/images/preview/mitech-landing-case-study-preview.jpg"
                    alt=""
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="layouts-section-text">
                  <h3 className="heading">
                    <span className="text-color-primary">
                      Tarifs attractifs
                    </span>
                    <br />
                    Qualité garantie
                  </h3>
                  <p className="text mt-30">
                    Notre équipe est polyvalente, multidisciplinaire et
                    professionnelle, nous utilisons toujours des meilleurs
                    outils technologiques pour vos projets. Notre objectif est
                    de garantir la rapidité de livraison et l'utilité de chaque
                    développement à vos besoins en optimisant le coût
                    d'investissement.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </Transition>
  );
};
export default Home;
