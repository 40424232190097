import React, { useState, useLayoutEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import 'component/plugin/transition.css';
const Transition = (props) => {
  const [display, setdisplay] = useState(false);
  useLayoutEffect(() => {
    const timeout = setTimeout(() => {
      setdisplay(true);
    }, 200);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <>
      <CSSTransition
        in={display}
        timeout={200}
        classNames="generic-item"
        appear
        unmountOnExit
      >
        {props.children}
      </CSSTransition>
    </>
  );
};
export default Transition;
