import { useState, useRef, useLayoutEffect } from 'react';

const useScroll = (classSroll, minHeightToScroll) => {
  const [scrollInfo, setscrollInfo] = useState({
    isScrollable: false,
    lastScrollTop: 0,
  });
  const ref = useRef();
  useLayoutEffect(() => {
    const handleSroll = (event) => {
      if (ref.current) {
        const currentPositionHeight = window.pageYOffset;
        const maxScroll = document.body.scrollHeight - window.innerHeight;
        const minValHeight = minHeightToScroll ? minHeightToScroll : 200;
        const cssClass = classSroll ? classSroll : 'show';
        if (
          currentPositionHeight > minValHeight &&
          currentPositionHeight < maxScroll
        ) {
          ref.current.classList.add(cssClass);
          setscrollInfo({
            isScrollable: true,
            lastScrollTop: currentPositionHeight,
          });
        } else {
          ref.current.classList.remove(cssClass);
          setscrollInfo({
            isScrollable: false,
            lastScrollTop: currentPositionHeight,
          });
        }
      }
    };
    document.addEventListener('scroll', handleSroll);
    return () => {
      document.removeEventListener('scroll', handleSroll);
    };
  }, []);
  return ref;
};
export default useScroll;
