export const language = 'fr';
export const ROUTES = {
	home: {
		path: '/home',
		label: 'accueil',
	},
	aboutus: {
		path: '/aboutus',
		label: 'Présentation',
	},
	services: {
		path: '/services',
		label: 'Nos services',
	},
	contact: {
		path: '/contact',
		label: 'Contactez-nous',
	},
	payment: {
		path: '/payment',
		label: 'Payment',
	},
	notfound: {
		path: null,
		label: '404 Page introuvable',
	},
};
export const ROUTE_HOME = {
	path: ROUTES.home.path,
	label: ROUTES.home.label,
};
export const ROUTE_ABOUT_US = {
	path: ROUTES.aboutus.path,
	label: ROUTES.aboutus.label,
};
export const ROUTE_SERVICES = {
	path: ROUTES.services.path,
	label: ROUTES.services.label,
};
export const ROUTE_CONTACT = {
	path: ROUTES.contact.path,
	label: ROUTES.contact.label,
	info: {
		tel: '01.49.65.91.07',
		email: 'contact@creativitywebm.com',
	},
};
export const URL_FUNC_SENDMAIL = 'https://europe-west1-creativitywebmedia.cloudfunctions.net/sendMail';

//export const URL_FUNC_SENDMAIL ='http://localhost:5001/creativitywebmedia/us-central1/sendMail'
/* export const URL_FUNC_STRIPE_PAYINT =
  'https://europe-west1-creativitywebmedia.cloudfunctions.net/sendMail'; */
export const URL_FUNC_STRIPE_PAYINT = 'http://localhost:5001/creativitywebmedia/us-central1/stripe';

export const MEDIA_SCREEN = {
	xlg: 1200,
	lg: 992,
	med: 768,
	sm: 576,
};

export const SWIPER_PARAMS_IMGMED = {
	pagination: {
		el: '.swiper-pagination',
		type: 'bullets',
	},
	breakpoints: {
		576: {
			slidesPerView: 1,
		},
		768: {
			slidesPerView: 2,
		},
		992: {
			slidesPerView: 3,
		},
		1200: {
			slidesPerView: 3,
		},
	},
};
export const SWIPER_PARAMS_BRAND = {
	pagination: {
		el: '.swiper-pagination',
		type: 'bullets',
	},
	breakpoints: {
		576: {
			slidesPerView: 2,
		},
		768: {
			slidesPerView: 2,
		},
		992: {
			slidesPerView: 6,
		},
		1200: {
			slidesPerView: 8,
		},
	},
	slideStyle: {
		width: '170px',
		marginRight: '50px',
	},
};
export const HOME_SLIDERS = [
	'undraw_apps_m7mh',
	'undraw_blogging_vpvv',
	'undraw_browsing_urt9',
	'undraw_code_thinking_1jeh',
	'undraw_code_typing_7jnv',
	//'undraw_detailed_analysis_xn7y',
	'undraw_detailed_examination_joef',
	'undraw_develop_app_kvt2',
	//'undraw_drag_5i9w',
	'undraw_file_sync_ot38',
	//'undraw_informed_decision_p2lh',
	'undraw_JavaScript_frameworks_8qpc',
	'undraw_Mobile_app_p3ts',
	'undraw_Mobile_application_mr4r',
	'undraw_mobile_development_8gyo',
	'undraw_open_source_1qxw',
	//'undraw_Operating_system_re_iqsc',
	// 'undraw_os_upgrade_nj2m',
	'undraw_programming_2svr',
	'undraw_progressive_app_m9ms',
	'undraw_setup_analytics_8qkl',
	//'undraw_source_code_xx2e',
	//'undraw_stepping_up_g6oo',
	'undraw_visionary_technology_33jy',
	//'undraw_web_development_w2vv',
	'undraw_web_devices_ad58',
	'undraw_website_setup_5hr2',
	'undraw_work_together_h63l',
];
export const HOME_SERVICES = [
	{
		//img: `${process.env.PUBLIC_URL}/assets/images/icons/mitech-processing-service-image-01-80x83.png`,
		img: 'undraw_static_website_0107',
		heading: 'Landing page',
		text:
			'Votre taux de conversion reste faible ? Notre solution vous aide à augmenter considérablement le nombre des visiteurs inconnus. Construisons ensemble de superbes pages web.',
		footing: `Découvrir l'offre`,
	},

	{
		//img: `${process.env.PUBLIC_URL}/assets/images/icons/mitech-processing-service-image-01-80x83.png`,
		img: 'undraw_content_team_3epn',
		heading: 'Création de contenu',
		text:
			'Rédaction du contenu est primordial pour votre société. Nous déterminons des mots clés pertinents pour optimiser votre visibilité de votre boutique dans les moteurs de recherche.',
		footing: `Vous avez un projet ?`,
	},

	{
		//img: `${process.env.PUBLIC_URL}/assets/images/icons/mitech-processing-service-image-01-80x83.png`,
		img: 'undraw_trends_a5mf',
		heading: 'Référencement',
		text: `Besoin d'améliorer votre classement dans les résultats de recherche tel que Google, Yahoo, Bing, etc. ? Nous vous accompagnons dans les choix stratégiques de référencement.`,
		footing: `Voir plus`,
	},

	{
		//img: `${process.env.PUBLIC_URL}/assets/images/icons/mitech-processing-service-image-01-80x83.png`,
		img: 'undraw_add_to_cart_vkjp',
		heading: 'E-commerce',
		text: `Vous voulez lancer dans l'ecommerce pour maximisez vos encaissements en France et à l'international ? Notre plateforme SaaS est le meilleur choix pour vos besoins et votre budget.`,
		footing: `Obtenir un devis`,
	},

	{
		//img: `${process.env.PUBLIC_URL}/assets/images/icons/mitech-processing-service-image-01-80x83.png`,
		img: 'undraw_smart_resize_wpn8',
		heading: 'Responsive Design et Multi-plateforme',
		text: `Il est dorénavant pertinent d'intégrer la notion de mobilité dans les projets de web. Notre travaillons avec vous pour conçevoir des sites responsives qui s'affichent aussi bien sur un ordinateur que sur un smartphone ou une tablette`,
		footing: `Je me lance`,
	},

	{
		//img: `${process.env.PUBLIC_URL}/assets/images/icons/mitech-processing-service-image-01-80x83.png`,
		img: 'undraw_product_teardown_elol',
		heading: 'Développement mobile',
		text: `Plus de 50% du temps de surf sur internet passent par les smartphones. Nos réalisations des applications mobiles exploitent les fonctionnalités natives (appareil photo, micro, etc.) pour s'adapter aux stratégies marketing de votre entreprise.`,
		footing: `Solution apportée`,
	},

	{
		//img: `${process.env.PUBLIC_URL}/assets/images/icons/mitech-processing-service-image-01-80x83.png`,
		img: 'undraw_going_up_ttm5',
		heading: 'Solutions Cloud',
		text: `Modernisez vos activités professionnelles sur le Cloud computing. Nos solutions sont industrialisées sur Google Cloud. Vous n'aurez plus à soucier les coûts des infrastructures et vous bénéficiez un service sécurisé selon vos budgets.`,
		footing: `Contacter notre équipe`,
	},
];
export const TIMELINE_SERVICES = [
	{
		step: 'Jour J',
		label: 'Mise en exploitation',
		text: `Après que l'application est validé et conforme aux spécifications, votre projet sera mis en production en toute sécurité.`,
		img: 'undraw_Security_on_ff2u',
	},
	{
		step: '1 à 2 semaines',
		label: 'Développement et test',
		text: `Pendant la phase de réalisation, notre équipe vous fournira un accès à l'environnement de développement pour que nous puissions suivre ensemble l'avancée de votre projet. Bien sûr vous pourriez à tout moment discuter avec nous pour des ajustements de l'application. Les tests sont primordiaux car ils assurent la qualité de l'application livrée. Une plateforme JIRA sera à votre place pour que vous puissiez faire part de vos remarques et les bugs seront corrigés selon les spécifications.`,
		img: 'undraw_in_progress_ql66',
		marginTop: true,
	},
	{
		step: '1 à 5 jours',
		label: 'Expression de votre projet',
		text: `Nous discuterons avec les acteurs de votre société afin de proposer une meilleure solution informatique adaptée à votre besoin d'usage. Les spécifications des logiciels seront définies à la suite en détaillant chaque fonctionnalité implémentée. Lorsque nous validons le cahier des charges, un délai sera estimé et nous nous engageons à le respecter.`,
		img: 'undraw_Preparation_re_t0ce',
	},
];
export const BRAND_LOGO = [
	{ 1: `${process.env.PUBLIC_URL}/assets/images/logo/react.svg` },
	{ 2: `${process.env.PUBLIC_URL}/assets/images/logo/nodejs-1.svg` },
	{ 3: `${process.env.PUBLIC_URL}/assets/images/logo/mongodb.svg` },
	{ 4: `${process.env.PUBLIC_URL}/assets/images/logo/bootstrap-4.svg` },
	{ 5: `${process.env.PUBLIC_URL}/assets/images/logo/algolia-1.svg` },
	{ 6: `${process.env.PUBLIC_URL}/assets/images/logo/firebase-1.svg` },
	{ 7: `${process.env.PUBLIC_URL}/assets/images/logo/google-cloud-1.svg` },
	{ 8: `${process.env.PUBLIC_URL}/assets/images/logo/github-1.svg` },
	{ 9: `${process.env.PUBLIC_URL}/assets/images/logo/gitlab.svg` },
	{ 10: `${process.env.PUBLIC_URL}/assets/images/logo/aws-2.svg` },
	{ 11: `${process.env.PUBLIC_URL}/assets/images/logo/adobe-illustrator-cc.svg` },
	{ 12: `${process.env.PUBLIC_URL}/assets/images/logo/after-effects-cc.svg` },
	{ 13: `${process.env.PUBLIC_URL}/assets/images/logo/redux.svg` },
	{ 14: `${process.env.PUBLIC_URL}/assets/images/logo/stripe-4.svg` },
	{ 15: `${process.env.PUBLIC_URL}/assets/images/logo/visual-studio-code-1.svg` },
	{ 16: `${process.env.PUBLIC_URL}/assets/images/logo/vue-js-1.svg` },
	{ 17: `${process.env.PUBLIC_URL}/assets/images/logo/indesign-cc.svg` },
	{ 18: `${process.env.PUBLIC_URL}/assets/images/logo/java-4.svg` },
	{ 19: `${process.env.PUBLIC_URL}/assets/images/logo/mysql-7.svg` },
	{ 20: `${process.env.PUBLIC_URL}/assets/images/logo/photoshop-cc.svg` },
	{ 21: `${process.env.PUBLIC_URL}/assets/images/logo/premiere-cc.svg` },
];
