import React, { useLayoutEffect } from 'react';
import Transition from 'component/plugin/Transition';
import { HOME_SLIDERS, HOME_SERVICES, ROUTE_CONTACT } from 'config/general';
import SwiperServices from 'component/plugin/SwiperServices';
import TimeLine from 'component/generic/TimeLine';
import TransportServices from 'component/generic/TransportServices';
const Services = () => {
  return (
    <Transition>
      <>
        
        <div className="projects-wrapper projectinfotechno-bg section-space--ptb_100">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title-wrap text-center section-space--mb_40">
                  <h6 className="section-sub-title mb-20">Nos prestations</h6>
                  <h3 className="heading">
                    Nous vous apportons &nbsp;
                    <span className="text-color-primary">
                      toute notre expertise
                    </span>
                  </h3>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <SwiperServices cssSwiper="projects-wrap swiper-container service-slider__container projects-wrap swiper-container projects-slider__container swiper-container-initialized swiper-container-horizontal" />
              </div>
            </div>
          </div>
        </div>
        <TimeLine />
        <TransportServices />
      </>
    </Transition>
  );
};
export default Services;
