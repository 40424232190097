import React from 'react';
import MenuList from 'component/menu/MenuList';

const Navigation = (props) => {
  return (
    <>
      {/* navigation menu */}
      <div className="header__navigation menu-style-four preview-menu d-none d-xl-block">
        <nav className="navigation-menu navigation-menu--onepage navigation-menu-right">
          <MenuList />
        </nav>
      </div>
    </>
  );
};
export default Navigation;
