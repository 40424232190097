import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ROUTE_CONTACT } from 'config/general.js';
import MobileMenu from 'component/menu/MobileMenu';
import { FaPhone, FaHeadset,FaMailBulk } from 'react-icons/fa';

const ActionMenu = (props) => {
  const [mobileMode, setmobileMode] = useState(false);
  const onMobileMenuClick = () => {
    setmobileMode(!mobileMode);
    if (mobileMode) {
      document.body.classList.add('no-overflow');
    } else {
      document.body.classList.remove('no-overflow');
    }
  };
  const closeMobileMenu = () => {
    setmobileMode(false);
    document.body.classList.remove('no-overflow');
  };
  return (
    <>
      {/* header actions */}
      <div className="header__actions--preview">
        <div className="header__actions">
          <div
            className="header__icons-wrapper header-right-inner"
            id="hidden-icon-wrapper"
          >
            <div className="header-button downlode-btn ml-4">
              <Link to={ROUTE_CONTACT.path} className="ht-btn ht-btn-md">
                <FaMailBulk className="mx-2" />
                {ROUTE_CONTACT.info.email} | <FaHeadset className="mr-2" />
                {ROUTE_CONTACT.info.tel}
              </Link>
            </div>
          </div>
          {/*  mobile menu */}
          <div
            className="mobile-navigation-icon d-block d-xl-none"
            id="mobile-menu-trigger"
            onClick={() => onMobileMenuClick()}
          >
            <i></i>
          </div>
          {/* hidden icons menu */}
          <div
            className="hidden-icons-menu d-block d-md-none"
            id="hidden-icon-trigger"
          >
            <a href={void(0)}>
              <i className="far fa-ellipsis-h-alt"></i>
            </a>
          </div>
        </div>
      </div>

      <MobileMenu activated={mobileMode} closeMobileMenu={closeMobileMenu} />
    </>
  );
};
export default ActionMenu;
