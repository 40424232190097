import { useState, useRef, useLayoutEffect } from 'react';

const useSticky = (classSticky) => {
  const ref = useRef();
  useLayoutEffect(() => {
    const handleSrollSticky = (event) => {
      if (ref.current) {
        const currentPositionHeight = window.pageYOffset;
        const valHeight = ref.current.clientHeight
          ? ref.current.clientHeight
          : 200;
        const screenSize = window.innerWidth;
        const cssClass = classSticky ? classSticky : 'is-sticky';
        if (screenSize >= 320) {
          if (currentPositionHeight < valHeight) {
            ref.current.classList.remove(cssClass);
          } else {
            ref.current.classList.add(cssClass);
          }
        }
      }
    };
    document.addEventListener('scroll', handleSrollSticky);
    return () => {
      document.removeEventListener('scroll', handleSrollSticky);
    };
  }, []);
  return ref;
};
export default useSticky;
