import React, { useEffect, useRef, useLayoutEffect, useContext } from 'react';

import { FaMailBulk } from 'react-icons/fa';
import useWow from 'hook/useWow';
import Header from 'component/generic/Header';
import ScrollUp from 'component/plugin/ScrollUp';
import { Route, Switch } from 'react-router-dom';
import PageNotFound from 'component/generic/PageNotFound';
import AboutUs from 'component/generic/AboutUs';
import Services from 'component/generic/Services';
import Contact from 'component/generic/Contact';
import Home from 'component/generic/Home';
import Breadcrumb from 'component/generic/Breadcrumb';
import Spinner from 'component/plugin/Spinner';
import Footer from 'component/generic/Footer';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'assets/css/vendor/cerebrisans.css';
import 'assets/css/plugins/animate.min.css';
import 'assets/scss/style.scss';
import './App.scss';
import {
  ROUTE_HOME,
  ROUTE_ABOUT_US,
  ROUTE_SERVICES,
  ROUTE_CONTACT,
} from 'config/general.js';
import { AppContext } from 'provider/AppProvider';
import CheckoutForm from 'component/payment/CheckoutForm';
const App = () => {
  const { initWow } = useWow();
  const { dimScreen } = useContext(AppContext);
  useLayoutEffect(() => {
    initWow();
  }, []);
  return (
    <>
      <Header />

      <Breadcrumb />

      <div id="main-wrapper">
        <div
          className="site-wrapper-reveal"
          style={{ minHeight: dimScreen.height }}
        >
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path={ROUTE_HOME.path} component={Home} />
            <Route exact path={ROUTE_ABOUT_US.path} component={AboutUs} />
            <Route exact path={ROUTE_SERVICES.path} component={Services} />
            <Route exact path={ROUTE_CONTACT.path} component={Contact} />
            <Route exact path={ROUTE_CONTACT.path} component={Contact} />
            {/* <Route exact path="/payment" component={CheckoutForm} /> */}
            <Route component={PageNotFound} />
          </Switch>
        </div>
      </div>
      <Footer />
      <ScrollUp />
      <Spinner />
    </>
  );
};

export default App;
